import React from "react";
import styled from "styled-components";
import { Background } from "react-imgix";
import { breakpoints } from "../styles/variables";
import { imgix } from "../data/data";
import { superScriptRHelperHTML } from "../data/superscriptHelper";

interface KontaktDatenSmallProps {
  inhalt: {
    image: [{ url: String; title: string }];
    smallAddressText: string;
  };
}

const KontaktDatenSmallDiv = styled.div`
  display: grid;
  grid-template-columns: 121px 1fr;
  grid-column-gap: 27px;
  margin-bottom: 33px;

  address {
    a[href$=".vcf"] {
      background: url("/assets/file-icons/doc.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }
  }

  &::last-of-type {
    margin-bottom: 0;
  }

  > div:first-of-type > div {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    height: 121px;
    background-position: center;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-column-gap: 30px;
    margin-bottom: 50px;
    grid-template-columns: 161px 1fr;

    > div:first-of-type > div {
      height: 161px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    grid-column-gap: 30px;
    margin-bottom: 60px;
    grid-template-columns: 189px 1fr;
    address {
      a[href$=".vcf"] {
        padding-left: 30px;
      }
    }
    > div:first-of-type > div {
      height: 189px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-column-gap: 40px;
    margin-bottom: 75px;
    grid-template-columns: 224px 1fr;
    address {
      a[href$=".vcf"] {
        padding-left: 34px;
      }
    }
    > div:first-of-type > div {
      height: 224px;
    }
  }
`;

const KontaktDatenSmall: React.FC<KontaktDatenSmallProps> = ({ inhalt }) => (
  <KontaktDatenSmallDiv>
    <div>
      {inhalt && inhalt.image && inhalt.image.length && inhalt.image[0] ? (
        <Background
          src={inhalt.image[0].url}
          imgixParams={imgix.gridImg}
          htmlAttributes={{ title: inhalt.image[0].title }}
        />
      ) : (
        ""
      )}
    </div>
    <div>
      <address
        dangerouslySetInnerHTML={{
          __html: superScriptRHelperHTML(inhalt.smallAddressText),
        }}
      ></address>
    </div>
  </KontaktDatenSmallDiv>
);

export default KontaktDatenSmall;
