import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import WhiteLead from "../components/WhiteLead";
import Kontakt from "../components/Kontakt";
import PartnerItem from "../components/PartnerItem";
import UeberUnsAkkordeon from "../components/UeberUnsAkkordeon";
import AkkordeonItem from "../components/Akkordeon/UeberUnsAkkordeon/AkkordeonItem";
import CenteredTextBlock from "../components/CenteredTextBlock";

import { Navigation, Keyboard, a11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import { breakpoints, colors } from "../styles/variables";
import WrapperSmallEqTopBtm from "../components/Layout/WrapperSmallEqTopBtm";
import UeberUnsTextImage from "../components/UeberUnsTextImage";

interface SwissBlockAGProps {
  data: {
    cms: {
      inhalt: {
        title: string;
        url: string;
        greenLead: string;
        whiteLead: string;
        kontaktInhalte: [
          {
            smallAddressText: string;
            image: { url: string; title: string };
            textLinks: string;
            textRechts: string;
          }
        ];
      };
    };
  };
}

export const PartnerSwiper = styled(Swiper)`
  .swiper-pagination-bullet-active {
    color: ${colors.green};
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${colors.green};
    height: 100%;
    background: white;
    width: 50px;
    top: 0;
  }
  .swiper-button-next {
    right: 0;
    padding-top: 25px;
  }

  .swiper-button-prev {
    left: 0;
    padding-top: 25px;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.green};
  }

  .swiper-container {
  }

  .swiper-slide {
    width: 150px;
    img {
      width: 100%;
      height: auto;
    }
  }
  max-width: 1106px;
  padding: 52px 87px;

  margin: 0 auto;

  /* .swiper-wrapper {
    justify-content: center;
  } */

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .swiper-button-next,
    .swiper-button-prev {
      padding-top: 16px;
    }
    .swiper-slide {
      width: 200px;
    }
  }
`;

export const SwissBlockAGQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        url
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_swissBlockAg_Entry {
          metaBeschreibung
          greenLead
          whiteLead
          uberUns
          visual {
            url
            title
          }
          kontaktInhalte {
            ... on CMS_kontaktSmall_Entry {
              smallAddressText
              image {
                url
                title
              }
            }
            ... on CMS_kontaktLarge_Entry {
              image {
                url
                title
              }
              textLinks
              textRechts
            }
          }
        }

        children(type: "kategorie") {
          title
          ... on CMS_kategorie_Entry {
            partnerText
          }
          children {
            title
            ... on CMS_partnerStelle_Entry {
              id
              logo {
                url
              }
              websiteUrl
            }
          }
        }
      }
      localisation: entry(type: "metaDatenLokalisierung", siteId: [$siteId]) {
        ... on CMS_metaDatenLokalisierung_Entry {
          ihreAnsprechpartnerSwissBlockAg
          uberUnsSwissBlockAg
        }
      }
    }
  }
`;

const SwissBlockAG: React.FC<SwissBlockAGProps> = ({ data }) => (
  <DefaultLayout
    siteId={data.cms.inhalt.siteId}
    title={data.cms.inhalt.title}
    //links={data.cms.inhalt.localized}
    beschreibung={
      data.cms.inhalt.metaBeschreibung
        ? data.cms.inhalt.metaBeschreibung
        : false
    }
  >
    <HeaderTitle inhalt={data.cms.inhalt.title} />
    <GreenLead inhalt={data.cms.inhalt.greenLead} />
    <UeberUnsAkkordeon>
      <AkkordeonItem
        title={data.cms.localisation.ihreAnsprechpartnerSwissBlockAg}
        white
      >
        <WhiteLead inhalt={data.cms.inhalt.whiteLead} black />
        <Kontakt inhalt={data.cms.inhalt.kontaktInhalte} />
      </AkkordeonItem>
      <AkkordeonItem title={data.cms.localisation.uberUnsSwissBlockAg}>
        <CenteredTextBlock inhalt={data.cms.inhalt.uberUns}></CenteredTextBlock>
        {data.cms.inhalt.visual && data.cms.inhalt.visual.length >= 1 ? (
          <UeberUnsTextImage image={data.cms.inhalt.visual[0]} />
        ) : (
          ""
        )}
      </AkkordeonItem>
      {data.cms.inhalt.children.map((entry, index) => {
        return (
          <AkkordeonItem title={entry.title} key={index}>
            {entry.partnerText ? (
              <CenteredTextBlock inhalt={entry.partnerText}></CenteredTextBlock>
            ) : (
              ""
            )}
            <PartnerSwiper
              modules={[Navigation, Keyboard]}
              navigation
              keyboard={{ enabled: true, onlyInViewport: true }}
              a11y={{
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
              }}
              spaceBetween={40}
              slidesPerView={"auto"}
              loop={true}
            >
              {entry.children.map((entry, index) => (
                <SwiperSlide key={index}>
                  <PartnerItem
                    title={entry.title}
                    link={entry.websiteUrl}
                    logo={entry.logo[0].url}
                  />
                </SwiperSlide>
              ))}
            </PartnerSwiper>
          </AkkordeonItem>
        );
      })}
    </UeberUnsAkkordeon>
  </DefaultLayout>
);

export default SwissBlockAG;
